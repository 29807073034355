
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            drawer: true,
        };
    },
    computed: {
        ...mapGetters({
            pageTitle: 'pageTitle',
            availableRoutes: 'routes/availableRoutes',
            user: 'user',
            userRole: 'userRole',
            userAffiliation: 'userAffiliation',
            userGroup: 'userGroup',
            userStore: 'userStore',
            userContractor: 'userContractor',
        }),
        sideMenuLinks() {
            return this.availableRoutes.filter(route => route.sideMenu !== false);
        },
        selectedLang() {
            return this.$i18n.locales.findIndex(l => l.code === this.$i18n.locale);
        },
        mobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        affiliationsName() {
            if (!this.$store.state.auth.loggedIn) {
                return '';
            }

            if (this.userAffiliation === 'group') {
                return this.userGroup.name;
            } else if (this.userAffiliation === 'store') {
                return this.userStore.name;
            } else if (this.userAffiliation === 'contractor') {
                return this.userContractor.name;
            } else {
                return this.$t('none');
            }
        },
        initials() {
            if (!this.$store.state.auth.loggedIn) {
                return '';
            }
            const names = this.user.name.split(' ').slice(0, 2);

            return names.map(i => i.charAt(0)).join('');
        },
    },
    created() {
        if (this.mobile) {
            this.drawer = false;
        }
    },
    methods: {
        changeLang(lang) {
            this.$store.dispatch('changeLocale', lang.code);
            this.$i18n.setLocale(lang.code);
        },
    },
};
