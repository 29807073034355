const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
    storeFilter: '',
    contractorFilter: '',
    deletedFilter: 0,
});

export const state = () => ({
    list: [],
    listTotal: 0,
    listMeta: initListMeta(),
    showDialog: false,
    currentlyEditingId: null,
    loading: {
        list: false,
        delete: false,
        save: false,
        block: false,
        welcomeEmail: false,
        passwordEmail: false,
    },
    formErrors: [],
});

export const mutations = {
    ADD_USER_LIST(state, userList) {
        state.list = userList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    SHOW_DIALOG_STATE(state, boolean) {
        state.showDialog = boolean;
    },
    CHANGE_CURRENTLY_EDITING(state, userId) {
        state.currentlyEditingId = userId;
    },
    UPDATE_FORM_ERRORS(state, errors) {
        state.formErrors = errors;
    },
};

export const getters = {
    currentlyEditing: state => state.list.find(user => user.id === state.currentlyEditingId) || {},
};

export const actions = {
    async getUsers({ state, commit, dispatch }) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const params = new URLSearchParams({
            display: itemsPerPage,
            page,
            include: 'affiliations',
        });

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        if (state.listMeta.storeFilter) {
            params.append('stores', state.listMeta.storeFilter);
        }

        if (state.listMeta.contractorFilter) {
            params.append('contractors', state.listMeta.contractorFilter);
        }

        if (state.listMeta.deletedFilter) {
            params.append('include_deleted', state.listMeta.deletedFilter);
        }

        try {
            const response = await this.$axios.get(`/users?${params}`);
            commit('ADD_USER_LIST', response.data.data);
            commit('CHANGE_LIST_TOTAL', response.data.meta.total);
            commit('CHANGE_LIST_META', {
                page: response.data.meta.current_page,
                itemsPerPage: response.data.meta.per_page,
            });
        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
    async deleteUsers({ commit, dispatch }, ids) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });
        const deleteUser = id => this.$axios.delete(`/users/${id}`);
        let promise;

        try {
            promise = await Promise.all(ids.map(i => deleteUser(i)));
            const successMsg = this.$i18n.t('deleted');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getUsers');
        }

        return promise;
    },
    async addUser({ commit, dispatch }, user) {
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });
        commit('UPDATE_FORM_ERRORS', []);

        try {
            await this.$axios.post('/users', user);
            commit('SHOW_DIALOG_STATE', false);
            dispatch('getUsers');
            const successMsg = this.$i18n.t('created');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-creating');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    async updateUser({ commit, dispatch }, user) {
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });
        commit('UPDATE_FORM_ERRORS', []);

        try {
            await this.$axios.put(`/users/${user.id}`, user);
            commit('SHOW_DIALOG_STATE', false);
            commit('CHANGE_CURRENTLY_EDITING', null);
            dispatch('getUsers');
            const successMsg = this.$i18n.t('edited');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-updating');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    async blockUsers({ commit, dispatch }, { ids, blockMsg }) {
        commit('CHANGE_LOADING_STATE', { item: 'block', loading: true });
        const blockUser = id => this.$axios.patch(`/users/${id}/block`, { block_message: blockMsg });
        let promise;

        try {
            promise = await Promise.all(ids.map(i => blockUser(i)));
            const successMsg = this.$i18n.t('selected-users-blocked');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-blocking-users');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'block', loading: false });
            dispatch('getUsers');
        }

        return promise;
    },
    async unblockUser({ commit, dispatch }, id) {
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });

        try {
            await this.$axios.patch(`/users/${id}/unblock`);
            const successMsg = this.$i18n.t('user-unblocked');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
            dispatch('getUsers');
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-unblocking-user');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    async restoreUser({ commit, dispatch }, id) {
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });

        try {
            await this.$axios.patch(`/users/${id}/restore`);
            const successMsg = this.$i18n.t('user-restored');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
            dispatch('getUsers');
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-restoring-user');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    async resetPasswordEmail({ commit, dispatch }, email) {
        commit('CHANGE_LOADING_STATE', { item: 'passwordEmail', loading: true });

        try {
            await this.$axios.post('/forgot-password', { email });
            const successMsg = this.$i18n.t('email-sent');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const errorMsg = this.$i18n.t('error-occurred-while-sending-email');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'passwordEmail', loading: false });
        }
    },
    async resendWelocmeEmail({ commit, dispatch }, userId) {
        commit('CHANGE_LOADING_STATE', { item: 'welcomeEmail', loading: true });

        try {
            await this.$axios.post(`/users/${userId}/resend-welcome-email`);
            const successMsg = this.$i18n.t('email-sent');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const errorMsg = this.$i18n.t('error-occurred-while-sending-email');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'welcomeEmail', loading: false });
        }
    },
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getUsers');
    },
};
