export const state = () => ({
    group: [
        {
            name: 'home', // i18n key
            title: 'home', // i18n key
            path: '/',
            auth: ['admin', 'user'],
        },
        {
            name: 'store',
            title: 'store-overview',
            path: '/group/stores',
            auth: ['admin'],
        },
        {
            name: 'service',
            title: 'service',
            path: '/group/services',
            auth: ['admin', 'user'],
        },
        {
            name: 'contractor',
            title: 'contractor',
            path: '/group/contractors',
            auth: ['admin', 'user'],
        },
        {
            name: 'user',
            title: 'user',
            path: '/group/users',
            auth: ['admin'],
        },
        {
            name: 'order',
            title: 'orders-overview',
            path: '/group/orders',
            auth: ['admin', 'user'],
        },
        {
            title: 'order',
            path: '/group/orders/:id',
            auth: ['admin', 'user'],
            sideMenu: false,
        },
        {
            name: 'setting',
            title: 'setting',
            path: '/group/settings',
            auth: ['admin'],
        },
    ],
    store: [
        {
            name: 'order',
            title: 'orders-overview',
            path: '/store/orders',
            auth: ['admin', 'user'],
        },
        {
            title: 'order',
            path: '/store/orders/:id',
            auth: ['admin', 'user'],
            sideMenu: false,
        },
        {
            name: 'contractor',
            title: 'contractor',
            path: '/store/contractors',
            auth: ['admin', 'user'],
        },
        {
            name: 'user',
            title: 'user',
            path: '/store/users',
            auth: ['admin'],
        },
    ],
    contractor: [
        {
            name: 'job',
            title: 'job',
            path: '/contractor/jobs',
            auth: ['user', 'admin'],
        },
        {
            name: 'job',
            title: 'job',
            path: '/contractor/jobs/:id',
            auth: ['user', 'admin'],
            sideMenu: false,
        },
        {
            name: 'terms-and-conditions',
            title: 'terms-and-conditions',
            path: '/contractor/terms',
            auth: ['admin'],
            sideMenu: false,
        },
        {
            name: 'user',
            title: 'user',
            path: '/contractor/users',
            auth: ['admin'],
        },
    ],
});

export const mutations = {
};

export const getters = {
    availableRoutes(state, getters, rootState, rootGetters) {
        const userAffiliation = rootGetters.userAffiliation;
        const userRole = rootGetters.userRole;

        if (!userAffiliation || !userRole) {
            return [];
        }

        const routes = state[userAffiliation] || [];
        const allowedRoutes = routes.filter(route => route.auth.includes(userRole));

        return allowedRoutes;
    },
};
