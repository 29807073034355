const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
});

export const state = () => ({
    list: [],
    listTotal: 0,
    listMeta: initListMeta(),
    loading: {
        list: false,
        delete: false,
        tagAction: false,
    },
    currentlyEditingId: null,
    showDialog: false,
});

export const mutations = {
    ADD_TAG_LIST(state, tagList) {
        state.list = tagList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    CHANGE_CURRENTLY_EDITING(state, serviceId) {
        state.currentlyEditingId = serviceId;
    },
    SHOW_DIALOG_STATE(state, boolean) {
        state.showDialog = boolean;
    },
};

export const getters = {
    currentlyEditing: state => state.list.find(tag => tag.id === state.currentlyEditingId) || {},
};

export const actions = {
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getTags');
    },
    async createTag({ commit, rootGetters, dispatch }, tag) {
        commit('CHANGE_LOADING_STATE', { item: 'tagAction', loading: true });
        const { ownerGroupId } = rootGetters;

        try {
            await this.$axios.post(`/groups/${ownerGroupId}/tags`, tag);
            dispatch('getTags');
            commit('SHOW_DIALOG_STATE', false);
            const message = this.$i18n.t('created');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            const message = this.$i18n.t('error-occurred-while-creating');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'tagAction', loading: false });
        }
    },
    async updateTag({ commit, dispatch }, tag) {
        commit('CHANGE_LOADING_STATE', { item: 'tagAction', loading: true });

        try {
            await this.$axios.put(`/tags/${tag.id}`, tag);
            dispatch('getTags');
            commit('SHOW_DIALOG_STATE', false);
            commit('CHANGE_CURRENTLY_EDITING', null);
            const message = this.$i18n.t('edited');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            const message = this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'tagAction', loading: false });
        }
    },
    async deleteTag({ commit, dispatch }, tagId) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });
        let promise;

        try {
            promise = await this.$axios.delete(`/tags/${tagId}`);

            const message = this.$i18n.t('deleted');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch {
            const message = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getTags');
        }

        return promise;
    },
    async getTags({ state, commit, dispatch, rootGetters }, options = {}) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const { ownerGroupId } = rootGetters;
        const params = options.params === null ? '' : new URLSearchParams({
            display: itemsPerPage,
            page,
            ...options.params,
        });

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/tags?${params}`);

            if (params === '') {
                commit('ADD_TAG_LIST', response.data);
            } else {
                commit('ADD_TAG_LIST', response.data.data);
                commit('CHANGE_LIST_TOTAL', response.data.meta.total);
                commit('CHANGE_LIST_META', {
                    page: response.data.meta.current_page,
                    itemsPerPage: response.data.meta.per_page,
                });
            }
        } catch (error) {
            console.error(error);
            const message = this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
};
