const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
});

export const state = () => ({
    list: [],
    listTotal: 0,
    listMeta: initListMeta(),
    loading: {
        list: false,
        serviceAction: false,
        delete: false,
        checklistTemplates: false,
    },
    checklistTemplates: [],
    currentlyEditingId: null,
    showDialog: false,
});

export const mutations = {
    ADD_SERVICE_LIST(state, servicesList) {
        state.list = servicesList;
    },
    ADD_CHECKLIST_TEMPLATES(state, checklistTemplates) {
        state.checklistTemplates = checklistTemplates;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    CHANGE_CURRENTLY_EDITING(state, serviceId) {
        state.currentlyEditingId = serviceId;
    },
    SHOW_DIALOG_STATE(state, boolean) {
        state.showDialog = boolean;
    },
};

export const getters = {
    currentlyEditing: state => state.list.find(service => service.id === state.currentlyEditingId) || {},
};

export const actions = {
    async getServices({ state, commit, dispatch, rootGetters }, options = {}) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const { ownerGroupId } = rootGetters;
        const params = options.params === null ? '' : new URLSearchParams({
            display: itemsPerPage,
            page,
            ...options.params,
            include: 'checklist_templates',
        });

        if (options.getAll) {
            params.delete('display');
            params.delete('page');
        }

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/services?${params}`);

            if (params === '' || options.getAll) {
                commit('ADD_SERVICE_LIST', response.data);
            } else {
                commit('ADD_SERVICE_LIST', response.data.data);
                commit('CHANGE_LIST_TOTAL', response.data.meta.total);
                commit('CHANGE_LIST_META', {
                    page: response.data.meta.current_page,
                    itemsPerPage: response.data.meta.per_page,
                });
            }
        } catch (error) {
            console.error(error);
            const message = this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
    async createService({ commit, rootGetters, dispatch }, service) {
        commit('CHANGE_LOADING_STATE', { item: 'serviceAction', loading: true });
        const { ownerGroupId } = rootGetters;

        try {
            await this.$axios.post(`/groups/${ownerGroupId}/services`, service);
            dispatch('getServices');
            commit('SHOW_DIALOG_STATE', false);
            const message = this.$i18n.t('created');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            const message = this.$i18n.t('error-occurred-while-creating');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'serviceAction', loading: false });
        }
    },
    async updateService({ commit, dispatch }, service) {
        commit('CHANGE_LOADING_STATE', { item: 'serviceAction', loading: true });

        try {
            await this.$axios.put(`/services/${service.id}`, service);
            dispatch('getServices');
            commit('SHOW_DIALOG_STATE', false);
            commit('CHANGE_CURRENTLY_EDITING', null);
            const message = this.$i18n.t('edited');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            const message = this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'serviceAction', loading: false });
        }
    },
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getServices');
    },
    async deleteServices({ commit, dispatch, rootGetters }, ids) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });
        const deleteService = id => this.$axios.delete(`services/${id}`);
        let promise;

        try {
            promise = await Promise.all(ids.map(i => deleteService(i)));
            const message = this.$i18n.t('deleted');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch {
            const message = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getServices');
        }

        return promise;
    },
    async getChecklistTemplates({ commit, dispatch, rootGetters }) {
        commit('CHANGE_LOADING_STATE', { item: 'checklistTemplates', loading: true });
        const { ownerGroupId } = rootGetters;

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/checklist-templates`);
            commit('ADD_CHECKLIST_TEMPLATES', response.data);
        } catch (error) {
            console.error(error);
            const message = this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'checklistTemplates', loading: false });
        }
    },
};
