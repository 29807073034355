
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: {
        ...mapGetters('notifications', [
            'notifications',
        ]),
    },
    methods: {
        ...mapActions('notifications', {
            close: 'deleteNotification',
        }),
    },
};
