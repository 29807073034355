
export default function({ $auth, $sentry, app }) {
    app.store.watch(() => app.store.getters.user, (user) => {
        if (!user || !$auth.loggedIn) {
            return;
        }

        // eslint-disable-next-line camelcase
        const { is_activated, name, id, email, username, affiliations } = user;

        $sentry.configureScope((scope) => {
            scope.setUser({
                username,
                name,
                id,
                email,
                is_activated,
                affiliations,
            });
        });
    }, { immediate: true });
}
