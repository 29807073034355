export default ({ app, $auth, store }) => {
    const i18n = {
        changeVuetifyLocale(locale) {
            const localeMap = {
                en: 'en',
                'nb-no': 'no',
                nb: 'no',
            };
            app.vuetify.framework.lang.current = localeMap[locale];
        },

        changeDayJsLocale(locale) {
            const localeMap = {
                en: 'en',
                'nb-no': 'nb',
                nb: 'nb',
            };
            app.$dayjs.locale(localeMap[locale]);
        },

        changeLocale(locale) {
            this.changeVuetifyLocale(locale);
            this.changeDayJsLocale(locale);
        },
    };
    const nuxtI18nlocaleMap = {
        en: 'en',
        nb: 'nb-no',
    };

    // run at init to set base lang
    i18n.changeLocale(app.i18n.locale);

    // Watch user locale and change app lang on change
    store.watch(state => state.auth.user?.locale, (newVal) => {
        if (newVal) {
            app.i18n.setLocale(nuxtI18nlocaleMap[newVal]);
            i18n.changeLocale(newVal);
        }
    }, { immediate: true });

    // Change vuetify lang right before setting a new locale
    app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
        i18n.changeLocale(newLocale);
    };

    // Auth redirect to page with correct lang prefix
    $auth.onRedirect((to) => {
        return app.localePath(to);
    });
};
