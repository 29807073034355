const initListMeta = () => ({
    tagFilter: '',
    startDate: '',
    endDate: '',
});

export const state = () => ({
    list: [],
    listMeta: initListMeta(),
    loading: {
        list: false,
        download: false,
    },
    showServiceStatisticsDialog: false,
    serviceSalesItems: [],
});

export const mutations = {
    ADD_STATISTICS_LIST(state, statsList) {
        state.list = statsList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    CHANGE_SHOW_SERVICE_STATISTICS_DIALOG(state, value) {
        state.showServiceStatisticsDialog = value;
    },
    CHANGE_SERVICE_SALES(state, data) {
        state.serviceSalesItems = data;
    },
};

export const actions = {
    async getStatistics({ state, commit, dispatch, rootGetters }, options = {}) {
        const loadingItem = options.download ? 'download' : 'list';
        commit('CHANGE_LOADING_STATE', { item: loadingItem, loading: true });

        const { ownerGroupId } = rootGetters;
        const params = new URLSearchParams({});
        let requestData = null;

        if (state.listMeta.tagFilter) {
            params.append('tags', state.listMeta.tagFilter);
        }

        if (state.listMeta.startDate) {
            params.append('start', state.listMeta.startDate);
        }

        if (state.listMeta.endDate) {
            params.append('end', state.listMeta.endDate);
        }

        if (options.download) {
            params.append('xlsx', 1);
            requestData = { responseType: 'blob' };
        }

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/statistics?${params}`, requestData);

            if (options.download) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.setAttribute('download', 'Statistikk.xlsx');
                document.body.appendChild(link);
                link.click();
            } else {
                commit('ADD_STATISTICS_LIST', response.data);
            }
        } catch (error) {
            console.error(error);
            const message = options.download ? 'failed-to-download-file' : 'error-occurred-while-getting-data';
            dispatch('notifications/addNotification', { type: 'error', message: this.$i18n.t(message) }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: loadingItem, loading: false });
        }
    },

    async getServiceStatistics({ state, commit, dispatch, rootGetters }, options) {
        const { ownerGroupId } = rootGetters;
        const params = new URLSearchParams({});
        let requestData = null;
        const serviceId = options.serviceId;

        if (state.listMeta.tagFilter) {
            params.append('tags', state.listMeta.tagFilter);
        }

        if (state.listMeta.startDate) {
            params.append('start', state.listMeta.startDate);
        }

        if (state.listMeta.endDate) {
            params.append('end', state.listMeta.endDate);
        }

        if (options.download) {
            params.append('xlsx', 1);
            requestData = { responseType: 'blob' };
        }

        const response = await this.$axios.get(`/groups/${ownerGroupId}/services/${serviceId}/store-statistics?${params}`, requestData);

        if (options.download) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.setAttribute('download', 'Statistikk.xlsx');
            document.body.appendChild(link);
            link.click();
        } else {
            commit('CHANGE_SERVICE_SALES', response.data);
            commit('CHANGE_SHOW_SERVICE_STATISTICS_DIALOG', true);
        }
    },
};
