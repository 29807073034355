'use strict';

function isObject(value) {
    return value === Object(value);
}

function isArray(value) {
    return Array.isArray(value);
}

/**
 * Check if ${value} is instance of File
 * We also accpet Blob objects if they have .name and .lastModifiedDate set.
 * @param {mixed} value
 */
function isFile(value) {
    return value instanceof File ||
        (value instanceof Blob && value.name && value.lastModifiedDate);
}

function makeArrayKey(key, index) {
    if (key.length > 2 && key.lastIndexOf('[]') === key.length - 2) {
        return key;
    } else {
        return key + '[' + index + ']';
    }
}

function objectToFormData(obj, fd, pre) {
    fd = fd || new FormData();

    Object.keys(obj).forEach(function(prop) {
        const key = pre ? (pre + '[' + prop + ']') : prop;

        if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
            objectToFormData(obj[prop], fd, key);
        } else if (isArray(obj[prop])) {
            obj[prop].forEach(function(value, index) {
                const arrayKey = makeArrayKey(key, index);

                if (isObject(value) && !isFile(value)) {
                    objectToFormData(value, fd, arrayKey);
                } else if (value instanceof Blob) {
                    // If type is Blob, filename isn't automatically appended
                    // if so, use a third argument for FormData.append.
                    fd.append(arrayKey, value, value.name);
                } else {
                    fd.append(arrayKey, value);
                }
            });
        } else if (obj[prop] !== null && obj[prop] !== undefined) {
            fd.append(key, obj[prop]);
        }
    });

    return fd;
}

module.exports = objectToFormData;
