/**
 * Function to compare two routes. With or without slugs/dynamic dirs
 * @param {String} authRoute route from routes.js vuex
 * @param {String} realRoute the real route user is navigated to
 */
export const URLcomparer = (authRoute, realRoute) => {
    const authDirectories = authRoute.split('/').filter(e => e !== ''); // [group, test, :id]
    const realDirectories = realRoute.split('/').filter(e => e !== ''); // [group, test, 1337]

    // Early return if not same length
    if (authDirectories.length !== realDirectories.length) {
        return false;
    }

    for (let i = 0; i < authDirectories.length; i++) {
        if (authDirectories[i].startsWith(':') && realDirectories[i]) {
            continue;
        } else if (authDirectories[i] !== realDirectories[i]) {
            return false;
        }
    }

    return true;
};

/**
 * Middleware to check if route navigated to is allowed to see
 * Uses vuex module 'routes'.
 * Checks routes object for current user affiliation and validates it's role.
 */
export default (context) => {
    const { store, route, app } = context;
    const userAffiliation = store.getters.userAffiliation;
    const userRole = store.getters.userRole;
    const pathNavigatedTo = route.path.replace(/\/+$/, '');
    const availableRoutes = store.state.routes[userAffiliation];
    const allowedRoutes = availableRoutes.filter(route => route.auth.includes(userRole));

    const allowed = !!allowedRoutes.find(r => URLcomparer(app.localePath(r.path), pathNavigatedTo));

    if (!allowed) {
        const error = new Error('Forbidden');
        error.statusCode = 403;
        throw error;
    }
};
