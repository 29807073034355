/**
 * Change vuex title on each route change
 */

import { URLcomparer } from '../middleware/roles';

export default ({ store, app }) => {
    app.router.afterEach((to) => {
        const routes = store.getters['routes/availableRoutes'];
        const vuexRoute = routes.find((route) => {
            let path = to.path;

            // replace trailing slash
            if (path !== '/') {
                path = path.replace(/\/+$/, '');
            }

            return URLcomparer(app.localePath(route.path), path);
        });

        if (vuexRoute) {
            store.commit('SET_PAGE_TITLE', vuexRoute.title);
        }
    });
};
