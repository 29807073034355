const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
    tagFilter: '',
});

export const state = () => ({
    list: [],
    listTotal: 0,
    listMeta: initListMeta(),
    loading: {
        list: false,
        contractorSave: false,
        delete: false,
    },
    currentlyEditingId: null,
    showDialog: false,
    formErrors: [],
});

export const mutations = {
    ADD_CONTRACTOR_LIST(state, contractorsList) {
        state.list = contractorsList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    CHANGE_CURRENTLY_EDITING(state, contractorId) {
        state.currentlyEditingId = contractorId;
    },
    SHOW_DIALOG_STATE(state, boolean) {
        state.showDialog = boolean;
    },
    UPDATE_FORM_ERRORS(state, errors) {
        state.formErrors = errors;
    },
};

export const getters = {
    currentlyEditing: state => state.list.find(contractor => contractor.id === state.currentlyEditingId) || null,
};

export const actions = {
    async getContractors({ state, commit, dispatch, rootGetters }, options = {}) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const { ownerGroupId } = rootGetters;
        const params = options.params === null ? '' : new URLSearchParams({
            display: itemsPerPage,
            page,
            include: 'stores,services,jobs,tags',
            ...options.params,
        });

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        if (state.listMeta.tagFilter) {
            params.append('tags', state.listMeta.tagFilter);
        }

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/contractors?${params}`);

            if (params === '') {
                commit('ADD_CONTRACTOR_LIST', response.data);
            } else {
                commit('ADD_CONTRACTOR_LIST', response.data.data);
                commit('CHANGE_LIST_TOTAL', response.data.meta.total);
                commit('CHANGE_LIST_META', {
                    page: response.data.meta.current_page,
                    itemsPerPage: response.data.meta.per_page,
                });
            }
        } catch (error) {
            const message = this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
    async createContractor({ commit, rootGetters, dispatch }, contractor) {
        commit('CHANGE_LOADING_STATE', { item: 'contractorSave', loading: true });
        const { ownerGroupId, userAffiliation, userStore } = rootGetters;
        const serviceIds = contractor.services.map(s => s?.id);
        const storeIds = contractor.stores.map(s => s?.id);
        commit('UPDATE_FORM_ERRORS', []);

        // Always add "self"-store to contractor if there is a store creating a contractor
        if (userAffiliation === 'store' && !storeIds.includes(userStore.id)) {
            storeIds.push(userStore.id);
        }

        try {
            const contractorResponse = await this.$axios.post(`/groups/${ownerGroupId}/contractors`, contractor);
            const contractorId = contractorResponse.data.id;

            await this.$axios.patch(`/contractors/${contractorId}/sync-services`, { ids: serviceIds });
            await this.$axios.patch(`/contractors/${contractorId}/sync-stores`, { ids: storeIds });
            await this.$axios.patch(`/contractors/${contractorId}/sync-tags`, { ids: contractor.tags });

            dispatch('getContractors');
            commit('SHOW_DIALOG_STATE', false);

            const message = this.$i18n.t('created');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const message = this.$i18n.t('error-occurred-while-creating');
                dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'contractorSave', loading: false });
        }
    },
    async updateContractor({ commit, dispatch }, contractor) {
        commit('CHANGE_LOADING_STATE', { item: 'contractorSave', loading: true });
        commit('UPDATE_FORM_ERRORS', []);
        const serviceIds = contractor.services.map(s => s?.id);
        const storeIds = contractor.stores.map(s => s?.id);
        const tagIds = contractor.tags.map(t => t?.id || t);

        try {
            const contractorResponse = await this.$axios.put(`/contractors/${contractor.id}`, contractor);
            const contractorId = contractorResponse.data.id;

            // Only add services/stores if they are not same as store
            const existingServiceIds = contractorResponse.data.services.map(s => s?.id);
            const existingStoreIds = contractorResponse.data.stores.map(s => s?.id);
            const existingTagIds = contractorResponse.data.tags.map(s => s?.id);

            if (JSON.stringify(serviceIds) !== JSON.stringify(existingServiceIds)) {
                await this.$axios.patch(`/contractors/${contractorId}/sync-services`, { ids: serviceIds });
            }

            if (JSON.stringify(storeIds) !== JSON.stringify(existingStoreIds)) {
                await this.$axios.patch(`/contractors/${contractorId}/sync-stores`, { ids: storeIds });
            }

            if (JSON.stringify(tagIds) !== JSON.stringify(existingTagIds)) {
                await this.$axios.patch(`/contractors/${contractorId}/sync-tags`, { ids: tagIds });
            }

            dispatch('getContractors');
            commit('SHOW_DIALOG_STATE', false);
            commit('CHANGE_CURRENTLY_EDITING', null);
            const message = this.$i18n.t('edited');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            console.error(error);
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const message = this.$i18n.t('error-occurred-while-updating');
                dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'contractorSave', loading: false });
        }
    },
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getContractors');
    },
    async deleteContractors({ commit, dispatch }, ids) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });
        const deleteStore = id => this.$axios.delete(`/contractors/${id}`);
        let promise;

        try {
            promise = await Promise.all(ids.map(i => deleteStore(i)));
            const message = this.$i18n.t('deleted');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch {
            const message = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getContractors');
        }

        return promise;
    },
};
