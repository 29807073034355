import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app }) => {
    Vue.use(VueGtag, {
        config: { id: process.env.GOOGLE_ANALYTICS_ID },
        appName: 'FerdigMontert',
        enabled: !!process.env.GOOGLE_ANALYTICS_ID,
        bootstrap: !!process.env.GOOGLE_ANALYTICS_ID,
        pageTrackerScreenviewEnabled: true,
    }, app.router);
};
