const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
    tagFilter: '',
});

export const state = () => ({
    list: [],
    listTotal: 0,
    listMeta: initListMeta(),
    showDialog: false,
    currentlyEditingId: null,
    loading: {
        list: false,
        delete: false,
        save: false,
    },
    formErrors: [],
    tags: [],
});

export const mutations = {
    ADD_STORE_LIST(state, storesList) {
        state.list = storesList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    SHOW_DIALOG_STATE(state, boolean) {
        state.showDialog = boolean;
    },
    CHANGE_CURRENTLY_EDITING(state, storeId) {
        state.currentlyEditingId = storeId;
    },
    UPDATE_FORM_ERRORS(state, errors) {
        state.formErrors = errors;
    },
    ADD_STORE_TAGS(state, tags) {
        state.tags = tags;
    },
};

export const getters = {
    currentlyEditing: state => state.list.find(store => store.id === state.currentlyEditingId) || null,
};

export const actions = {
    async getStores({ state, commit, dispatch, rootGetters }, options = {}) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const { ownerGroupId } = rootGetters;
        const params = options.params === null ? '' : new URLSearchParams({
            display: itemsPerPage,
            page,
            include: 'services,tags',
            ...options.params,
        });

        if (options.getAll) {
            params.delete('display');
            params.delete('page');
        }

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        if (state.listMeta.tagFilter) {
            params.append('tags', state.listMeta.tagFilter);
        }

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/stores?${params}`);

            if (params === '' || options.getAll) {
                commit('ADD_STORE_LIST', response.data);
            } else {
                commit('ADD_STORE_LIST', response.data.data);
                commit('CHANGE_LIST_TOTAL', response.data.meta.total);
                commit('CHANGE_LIST_META', {
                    page: response.data.meta.current_page,
                    itemsPerPage: response.data.meta.per_page,
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
    async deleteStores({ state, commit, dispatch }, ids) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });
        const deleteStore = id => this.$axios.delete(`/stores/${id}`);
        let promise;

        try {
            promise = await Promise.all(ids.map(i => deleteStore(i)));
            const successMsg = this.$i18n.t('stores-deleted-successfully');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getStores');
        }

        return promise;
    },
    async addStore({ state, commit, dispatch, rootGetters }, store) {
        const { ownerGroupId } = rootGetters;
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });
        commit('UPDATE_FORM_ERRORS', []);
        const serviceIds = store.services.map(s => s?.id);

        try {
            const storeResponse = await this.$axios.post(`/groups/${ownerGroupId}/stores`, store);
            const storeId = storeResponse.data.id;

            await this.$axios.patch(`/stores/${storeId}/sync-services`, { ids: serviceIds });
            await this.$axios.patch(`/stores/${storeId}/sync-tags`, { ids: store.tags });

            commit('SHOW_DIALOG_STATE', false);
            dispatch('getStores');
            const successMsg = this.$i18n.t('created');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-creating');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    async updateStore({ state, commit, dispatch, rootGetters }, store) {
        commit('CHANGE_LOADING_STATE', { item: 'save', loading: true });
        commit('UPDATE_FORM_ERRORS', []);
        const serviceIds = store.services.map(s => s?.id);
        const tagIds = store.tags.map(t => t?.id || t);

        try {
            const storeResponse = await this.$axios.put(`/stores/${store.id}`, store);
            const storeId = storeResponse.data.id;

            const existingServiceIds = storeResponse.data.services.map(s => s?.id);
            const existingTagIds = storeResponse.data.tags.map(s => s?.id);

            if (JSON.stringify(serviceIds) !== JSON.stringify(existingServiceIds)) {
                await this.$axios.patch(`/stores/${storeId}/sync-services`, { ids: serviceIds });
            }

            if (JSON.stringify(tagIds) !== JSON.stringify(existingTagIds)) {
                await this.$axios.patch(`/stores/${storeId}/sync-tags`, { ids: tagIds });
            }

            commit('SHOW_DIALOG_STATE', false);
            commit('CHANGE_CURRENTLY_EDITING', null);
            dispatch('getStores');
            const successMsg = this.$i18n.t('edited');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', validationErrors);
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-updating');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'save', loading: false });
        }
    },
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getStores');
    },
    async getTags({ commit, dispatch, rootGetters }) {
        const { ownerGroupId } = rootGetters;

        try {
            const response = await this.$axios.get(`/groups/${ownerGroupId}/tags`);
            commit('ADD_STORE_TAGS', response.data);
        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        }
    },
};
