import allSettled from 'promise.allsettled';
import object2formdata from '~/static/object2formdata';

// polyfill Promise.allSettled()
allSettled.shim();

const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
    storeFilter: '',
    statusFilter: '',
});

export const state = () => ({
    list: [],
    listTotal: 0,
    order: {
        client_calling_code: '47',
    },
    listMeta: initListMeta(),
    loading: {
        list: false,
        delete: false,
        deleteJob: false,
        order: false,
        saveOrder: false,
    },
    formErrors: [],
    jobsErrors: [],
    initListFetched: false,
});

export const mutations = {
    ADD_ORDER_LIST(state, ordersList) {
        state.list = ordersList;
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading[item] = loading;
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    ADD_ORDER(state, order) {
        state.order = order;
    },
    UPDATE_FORM_ERRORS(state, errors) {
        state.formErrors = errors;
    },
    ADD_JOBS_ERRORS(state, error) {
        state.jobsErrors.push(error);
    },
    CLEAR_FORM_ERRORS(state) {
        state.formErrors = [];
    },
    CLEAR_VALIDATION_ERRORS(state) {
        state.formErrors = [];
        state.jobsErrors = [];
    },
    UPDATE_JOB(state, { index, jobData }) {
        state.order.jobs.splice(index, 1, jobData);
    },
    REMOVE_JOB(state, id) {
        const jobIndex = state.order.jobs.findIndex(job => job.id === id);

        if (jobIndex !== -1) {
            state.order.jobs.splice(jobIndex, 1);
        }
    },
    UPDATE_INIT_LIST_FETCHED(state, isFetched) {
        state.initListFetched = isFetched;
    }
};

export const actions = {
    async saveOrder({ dispatch, commit, rootGetters, state }, { order, storeId, send }) {
        commit('CHANGE_LOADING_STATE', { item: 'saveOrder', loading: true });
        const { userStore } = rootGetters;

        try {
            try {
                commit('CLEAR_FORM_ERRORS');
                const isNew = !order.id;
                let orderResp;
                const params = new URLSearchParams({
                    include: 'stores',
                });

                if (isNew) {
                    const storeIdToUse = userStore?.id || storeId;
                    orderResp = await this.$axios.post(`/stores/${storeIdToUse}/orders?${params}`, order);
                } else {
                    orderResp = await this.$axios.put(`/orders/${order.id}?${params}`, order);
                }

                // Add jobs data to vuex (we want to save these in a new call)
                const orderWithUnsavedJobs = { ...orderResp.data, jobs: order.jobs };
                commit('ADD_ORDER', orderWithUnsavedJobs);
            } catch (error) {
                const validationErrors = error?.response?.data?.errors;
                const statusCode = error?.response?.status;

                if (statusCode === 422 && validationErrors) {
                    commit('UPDATE_FORM_ERRORS', validationErrors);

                    // Throw "fake" 422 if any of the jobs fail to save due to validation errors
                    throw new Error('Validation error');
                } else {
                    throw error;
                }
            }

            if (order.jobs) {
                const checklistPromises = [];
                const jobPromises = order.jobs.map((orderJob) => {
                    const job = JSON.parse(JSON.stringify(orderJob)); // to prevent vuex mutation errors

                    const isNew = !job.id;
                    let jobPromise;
                    const isOpen = !job.id || job.status === 'DECLINED' || job.status === 'DRAFT';
                    const params = new URLSearchParams({
                        include: 'files,service,contractor,products,requests,requests.contractor,checklists.checkpoints',
                    });

                    // Do not send request if job is locked
                    if (!isOpen) {
                        return null;
                    }

                    // parse/stringify removes the file references, so add them back
                    job.files_info = orderJob.files_info;

                    // turn stuff true/false to 1/0
                    job.has_products_delivered = job.has_products_delivered | 0;

                    // To update checklists - we have to make a separate call for each checklist
                    job.checklists.forEach((checklist) => {

                        if (checklist.type !== 'CASHIER' || !checklist.id) {
                            return;
                        }

                        if (send) { // when we send job request, checklsit can't have nulls
                            // turn stuff true/false to 1/0
                            checklist.checkpoints.forEach((cp) => {
                                cp.is_irrelevant = cp.is_irrelevant | 0;
                            });
                        }

                        let checklistPromise = this.$axios.put(`/checklists/${checklist.id}`, checklist);
                        checklistPromises.push(checklistPromise);
                    });

                    const formData = object2formdata(job);

                    // post unsaved and patch existing jobs
                    if (isNew) {
                        jobPromise = this.$axios.post(`/orders/${state.order.id}/jobs?${params}`, formData);
                    } else {
                        formData.append('_method', 'PUT');
                        jobPromise = this.$axios.post(`/jobs/${job.id}?${params}`, formData);
                    }

                    return jobPromise;
                });

                commit('CLEAR_VALIDATION_ERRORS');

                // Send all jobs and checlists individually and handle errors with allSettled
                const promises = [...jobPromises, checklistPromises];
                const settled = await Promise.allSettled(promises);

                settled.forEach((prom, index) => {
                    // Do not do anything if job is locked (null)
                    if (prom.value === null) {
                        return;
                    }

                    if (prom.status === 'fulfilled') {
                        // add/update the job to get ID from BE
                        if (prom.value?.data?.status) { // only job update, not checklists
                            const jobData = prom.value.data;
                            commit('UPDATE_JOB', { index, jobData });
                        }
                    }

                    if (prom.status === 'rejected') {
                        const statusCode = prom.reason?.response?.status;
                        const validationErrors = prom.reason?.response?.data?.errors;

                        if (statusCode === 422) {
                            commit('ADD_JOBS_ERRORS', { index, errors: validationErrors });
                        }
                    }
                });

                const rejectedJobRequest = settled.filter(prom => prom.status === 'rejected');

                if (rejectedJobRequest.length >= 1) {
                    const justValidationErrors = rejectedJobRequest.every(prom => prom.reason?.response?.status === 422);

                    if (justValidationErrors) {
                        // Throw "fake" 422 if any of the jobs fail to save due to validation errors
                        throw new Error('Validation error');
                    }

                    throw new Error('Unexpected error');
                }
            }

            if (send && order.jobs) { // Sending job_requests
                const promises = order.jobs.map((job) => {
                    const isOpen = !job.id || job.status === 'DECLINED' || job.status === 'DRAFT';
                    const params = new URLSearchParams({
                        include: 'job, job.files,job.service,job.contractor,job.products,job.requests,job.requests.contractor,job.checklists.checkpoints',
                    });

                    // Do not send request if job is locked
                    if (!isOpen) {
                        return null;
                    }

                    const jobRequest = job.requests.find(request => !request.sent_at)
                    const jobPromise = this.$axios.patch(`/job-requests/${jobRequest.id}/send?${params}`);

                    return jobPromise;
                });

                const settled = await Promise.allSettled(promises);
                settled.forEach((prom, index) => {

                    if (prom.status === 'fulfilled' && prom?.value?.data) {
                        // add/update the job to get ID from BE
                        const jobData = prom.value.data.job;
                        commit('UPDATE_JOB', { index, jobData });
                    }
                });

                const rejectedJobRequest = settled.filter(prom => prom.status === 'rejected');

                if (rejectedJobRequest.length >= 1) {
                    throw new Error('Unexpected error');
                }
            }

            // Things went well
            const message = send ? this.$i18n.t('order-saved-and-sent') : this.$i18n.t('order-saved');
            dispatch('notifications/addNotification', { type: 'success', message, duration: 1500 }, { root: true });
        } catch (error) {
            if (error.message === 'Validation error') {
                const message = this.$i18n.t('validation-error');
                dispatch('notifications/addNotification', { type: 'error', message, duration: 2500 }, { root: true });

                return;
            }

            const message = this.$i18n.t('error-occurred-while-creating');
            dispatch('notifications/addNotification', { type: 'error', message }, { root: true });
            console.error(error);
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'saveOrder', loading: false });
        }
    },
    async getOrder({ commit, dispatch }, orderId) {
        commit('CHANGE_LOADING_STATE', { item: 'order', loading: true });
        const params = new URLSearchParams({
            include: 'stores,store.services,jobs.files,jobs.service,jobs.contractor,jobs.requests,jobs.time_requests,jobs.products,jobs.checklists.checkpoints',
        });

        try {
            const response = await this.$axios.get(`/orders/${orderId}?${params}`);
            commit('ADD_ORDER', response.data);
        } catch (error) {
            console.error(error);
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'order', loading: false });
        }
    },
    async getOrders({ state, commit, dispatch }) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { page, itemsPerPage } = state.listMeta;
        const params = new URLSearchParams({
            display: itemsPerPage,
            page,
            include: 'stores,jobs',
        });

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        if (state.listMeta.storeFilter) {
            params.append('stores', state.listMeta.storeFilter);
        }

        if (state.listMeta.statusFilter) {
            params.append('status', state.listMeta.statusFilter);
        }

        try {
            const response = await this.$axios.get(`/orders?${params}`);
            commit('ADD_ORDER_LIST', response.data.data);
            commit('CHANGE_LIST_TOTAL', response.data.meta.total);
            commit('CHANGE_LIST_META', {
                page: response.data.meta.current_page,
                itemsPerPage: response.data.meta.per_page,
            });
            commit('UPDATE_INIT_LIST_FETCHED', true);

        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },
    async deleteOrders({ state, commit, dispatch }, ids) {
        commit('CHANGE_LOADING_STATE', { item: 'delete', loading: true });

        const deleteOrder = async id => await this.$axios.delete(`/orders/${id}`);

        try {
            await Promise.all(ids.map(i => deleteOrder(i)));
            const successMsg = this.$i18n.t('orders-deleted-successfully');
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'delete', loading: false });
            dispatch('getOrders');
        }
    },
    async deleteJob({ commit, dispatch }, id) {
        commit('CHANGE_LOADING_STATE', { item: 'deleteJob', loading: true });

        try {
            await this.$axios.delete(`/jobs/${id}`);
            const successMsg = this.$i18n.t('job-deleted-successfully');
            commit('REMOVE_JOB', id);
            dispatch('notifications/addNotification', { type: 'success', message: successMsg, duration: 1500 }, { root: true });
        } catch {
            const errorMsg = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'deleteJob', loading: false });
            dispatch('getOrders');
        }
    },
    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getOrders');
    },
};
