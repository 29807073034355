import object2formdata from '~/static/object2formdata';

const initListMeta = () => ({
    page: 1,
    itemsPerPage: 10,
    searchQuery: '',
    statusFilter: '',
    serviceFilter: '',
});

const initJob = () => ({
    contractor: {},
    service: {},
    order: {},
    products: [],
    files_info: [],
    time_requests: [],
    change_orders: [],
    timeline: [],
});
const initLoading = () => ({
    list: false,
    job: false,
    jobAccept: false,
    jobDecline: false,
    jobComplete: false,
    jobStart: false,
    change_order_delete: false,
    change_order_save: false,
    change_order_cancel: false,
    timeRequest: false,
    timeRequestCancel: false,
    renewToken: false,
    message: false,
    jobChecklist: false,
});

export const state = () => ({
    list: [],
    job: initJob(),
    listTotal: 0,
    listMeta: initListMeta(),
    loading: initLoading(),
    showMessageInput: false,
    formErrors: [],
});

export const mutations = {
    ADD_JOB_LIST(state, jobList) {
        state.list = jobList;
    },
    ADD_JOB(state, job) {
        state.job = job;
    },
    ADD_MORE_JOB_LIST(state, jobList) {
        state.list = state.list.concat(jobList);
    },
    CHANGE_LOADING_STATE(state, { item, loading }) {
        state.loading = { ...state.loading, [item]: loading };
    },
    CHANGE_LIST_TOTAL(state, itemsCount) {
        state.listTotal = itemsCount;
    },
    CHANGE_LIST_META(state, listMetaObj) {
        state.listMeta = { ...state.listMeta, ...listMetaObj };
    },
    CLEAR_LIST_META(state) {
        state.listMeta = initListMeta();
    },
    ADD_TIME_REQUEST(state, request) {
        state.job.time_requests.push(request);
    },
    CHANGE_TIMELINE(state, timeline) {
        state.job.timeline = timeline;
    },
    UPDATE_JOB_STATUS(state, newStatus) {
        state.job.status = newStatus;
    },
    UPDATE_FORM_ERRORS(state, errors) {
        state.formErrors = errors;
    },
    REMOVE_CHANGE_ORDER(state, id) {
        const changeOrderIndex = state.job.change_orders.findIndex(changeOrder => changeOrder.id === id);

        if (changeOrderIndex !== -1) {
            state.job.change_orders.splice(changeOrderIndex, 1);
        }
    },
    SHOW_MESSAGE_INPUT_STATE(state, boolean) {
        state.showMessageInput = boolean;
    },
    ADD_CHANGE_ORDER(state, changeOrder) {
        state.job.change_orders.push(changeOrder);
    },
    UPDATE_CHANGE_ORDER(state, updatedChangeOrder) {
        const changeOrders = state.job.change_orders;
        const changeOrderIndex = changeOrders.findIndex(changeOrder => changeOrder.id === updatedChangeOrder.id);

        if (changeOrderIndex !== -1) {
            changeOrders.splice(changeOrderIndex, 1, updatedChangeOrder);
        }
    },
    CLEAR_JOBS_STATE(state) {
        state.list = [];
        state.job = initJob();
        state.listTotal = 0;
        state.listMeta = initListMeta();
        state.loading = initLoading();
        state.showMessageInput = false;
        state.formErrors = [];
    },
    CHANGE_CHECKLIST(state, { checklist, type }) {
        const index = state.job.checklists?.findIndex(i => i.type === type);

        if (index !== -1) {
            state.job.checklists.splice(index, 1, checklist);
        }
    },
};

export const actions = {
    async saveChangeOrder({ state, commit, dispatch }, { changeOrder, changeOrderIndex }) {
        const jobId = state.job.id;
        const changeOrderId = changeOrder.id;
        commit('CHANGE_LOADING_STATE', { item: 'change_order_save', loading: true });
        commit('UPDATE_FORM_ERRORS', []);
        let promise;
        const formData = object2formdata(changeOrder);
        const params = new URLSearchParams({
            include: 'requests,lines,lines.files_info',
        });

        try {
            if (changeOrderId) {
                // Hack to PUT formdata 🤡
                formData.append('_method', 'PUT');
                promise = await this.$axios.post(`/change-orders/${changeOrderId}?${params}`, formData);
            } else {
                promise = await this.$axios.post(`/jobs/${jobId}/change-orders?${params}`, formData);
            }

            const dBChangeOrder = promise.data;

            if (changeOrderId) {
                commit('UPDATE_CHANGE_ORDER', dBChangeOrder);
            } else {
                commit('ADD_CHANGE_ORDER', dBChangeOrder);
            }

            const message = this.$i18n.t('change-order-saved', { number: changeOrderIndex + 1 });
            dispatch('notifications/addNotification', { type: 'success', message, duration: 2500 }, { root: true });
        } catch (error) {
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', [{ index: changeOrderIndex, errors: validationErrors }]);

                const message = this.$i18n.t('validation-error');
                dispatch('notifications/addNotification', { type: 'error', message, duration: 2500 }, { root: true });
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-creating');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'change_order_save', loading: false });
        }

        return promise;
    },
    async sendChangeOrder({ commit, dispatch }, { changeOrder, changeOrderIndex }) {
        commit('CHANGE_LOADING_STATE', { item: 'change_order_send', loading: true });
        commit('UPDATE_FORM_ERRORS', []);
        let promise;
        const params = new URLSearchParams({
            include: 'change_order.requests,change_order.lines,change_order.lines.files_info',
        });

        const saveChangeOrderResp = await dispatch('saveChangeOrder', { changeOrder, changeOrderIndex });

        // Saving change_order failed, stop operation
        if (saveChangeOrderResp === undefined) {
            commit('CHANGE_LOADING_STATE', { item: 'change_order_send', loading: false });

            return;
        }

        try {
            promise = await this.$axios.patch(`/change-orders/${saveChangeOrderResp.data.id}/send?${params}`);
            const dBChangeOrder = promise?.data?.change_order;
            commit('UPDATE_CHANGE_ORDER', dBChangeOrder);
            const message = this.$i18n.t('change-order-sent-to-costumer', { number: changeOrderIndex + 1 });
            dispatch('notifications/addNotification', { type: 'success', message, duration: 2500 }, { root: true });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const validationErrors = error?.response?.data?.errors;
            const statusCode = error?.response?.status;

            if (statusCode === 422 && validationErrors) {
                commit('UPDATE_FORM_ERRORS', [{ changeOrderIndex, errors: validationErrors }]);

                const message = this.$i18n.t('validation-error');
                dispatch('notifications/addNotification', { type: 'error', message, duration: 2500 }, { root: true });
            } else {
                const errorMsg = this.$i18n.t('error-occurred-while-sending');
                dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
            }
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'change_order_send', loading: false });
        }

        return promise;
    },
    async deleteChangeOrder({ state, commit, dispatch }, { id, index }) {
        commit('CHANGE_LOADING_STATE', { item: 'change_order_delete', loading: true });
        let promise;

        try {
            promise = await this.$axios.delete(`/change-orders/${id}`);
            commit('REMOVE_CHANGE_ORDER', id);
            const message = this.$i18n.t('change-order-deleted', { number: index + 1 });
            dispatch('notifications/addNotification', { type: 'success', message, duration: 2500 }, { root: true });
        } catch (error) {
            const errorMsg = this.$i18n.t('error-occurred-while-deleting');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'change_order_delete', loading: false });
        }

        return promise;
    },
    async cancelChangeOrderRequest({ state, commit, dispatch }, requestId) {
        commit('CHANGE_LOADING_STATE', { item: 'change_order_cancel', loading: true});
        commit('UPDATE_FORM_ERRORS', []);
        let promise;
        const params = new URLSearchParams({
            include: 'change_order.requests,change_order.lines,change_order.lines.files_info,change_order.job.timeline',
        });

        try {
            promise = await this.$axios.patch(`/change-order-requests/${requestId}/cancel?${params}`);
            const dBChangeOrder = promise?.data?.change_order;
            commit('UPDATE_CHANGE_ORDER', dBChangeOrder);
            commit('CHANGE_TIMELINE', promise.data.change_order.job.timeline);
        }
        catch (error) {
            console.error(error);
            const errorMsg = error?.response?.data?.message || this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        }
        finally {
            commit('CHANGE_LOADING_STATE', { item: 'change_order_cancel', loading: false});
        }

        return promise;
    },
    async acceptJob({ state, commit, dispatch }, requestId) {
        commit('CHANGE_LOADING_STATE', { item: 'jobAccept', loading: true });

        try {
            const response = await this.$axios.patch(`/job-requests/${requestId}/accept?include=job,job.timeline`);
            commit('UPDATE_JOB_STATUS', response.data.job.status);
            commit('CHANGE_TIMELINE', response.data.job.timeline);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const errorMsg = error?.response?.data?.message || this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'jobAccept', loading: false });
        }
    },
    async declineJob({ state, commit, dispatch }, requestId) {
        commit('CHANGE_LOADING_STATE', { item: 'jobDecline', loading: true });

        try {
            const response = await this.$axios.patch(`/job-requests/${requestId}/decline?include=job,job.timeline`);
            commit('UPDATE_JOB_STATUS', response.data.job.status);
            commit('CHANGE_TIMELINE', response.data.job.timeline);
            this.$router.push('.');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const errorMsg = error?.response?.data?.message || this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'jobDecline', loading: false });
        }
    },
    async startJob({ state, commit, dispatch }, jobId) {
        commit('CHANGE_LOADING_STATE', { item: 'jobStart', loading: true });

        try {
            const response = await this.$axios.patch(`/jobs/${jobId}/start-job?include=timeline`);
            commit('UPDATE_JOB_STATUS', response.data.status);
            commit('CHANGE_TIMELINE', response.data.timeline);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const errorMsg = error?.response?.data?.message || this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'jobStart', loading: false });
        }
    },
    async getJob({ state, commit, dispatch }, jobId) {
        commit('CHANGE_LOADING_STATE', { item: 'job', loading: true });
        const params = new URLSearchParams({
            include: 'contractor,order,products,requests,service,time_requests,requests.job,order.store,change_orders,change_orders.lines,change_orders.lines.files_info,change_orders.requests,files_info,timeline,checklists,checklists.checkpoints,checklists.checkpoints.files_info',
        });

        try {
            const response = await this.$axios.get(`/jobs/${jobId}?${params}`);
            commit('ADD_JOB', response.data);
            commit('CHANGE_LOADING_STATE', { item: 'job', loading: false });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            let errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');

            if (error.response.status === 403) {
                errorMsg = this.$i18n.t('you-do-not-have-access-to-this-job');
            }
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        }
    },
    async addTimeRequest({ state, commit, dispatch }, { jobId, request }) {
        commit('CHANGE_LOADING_STATE', { item: 'timeRequest', loading: true });

        try {
            const response = await this.$axios.post(`/jobs/${jobId}/job-time-requests?include=job,job.timeline`, request);
            commit('ADD_TIME_REQUEST', response.data);
            commit('CHANGE_TIMELINE', response.data.job.timeline);
        } catch (error) {
            const errorMsg = error?.response?.data?.errors?.suggested_time[0] || this.$i18n.t('error-occurred-while-creating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'timeRequest', loading: false });
        }
    },
    async cancelTimeRequest({ state, commit, dispatch }, requestId) {
        commit('CHANGE_LOADING_STATE', { item: 'timeRequestCancel', loading: true });

        try {
            const response = await this.$axios.patch(`/job-time-requests/${requestId}/cancel?include=job,job.timeline`);
            commit('UPDATE_JOB_STATUS', response.data.job.status);
            commit('CHANGE_TIMELINE', response.data.job.timeline);
        } catch (error) {
            const errorMsg = error?.response?.data?.message || this.$i18n.t('error-occurred-while-updating');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'timeRequestCancel', loading: false });
        }
    },
    async completeJob({ state, commit, dispatch }, jobId) {
        commit('CHANGE_LOADING_STATE', { item: 'jobComplete', loading: true });

        try {
            const response = await this.$axios.patch(`/jobs/${jobId}/end-job`);
            commit('UPDATE_JOB_STATUS', response.data.status);
            this.$router.push({ hash: 'description' });
        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('an-error-occurred-while-finishing-job');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'jobComplete', loading: false });
        }
    },
    async renewJobApproveToken({ state, commit, dispatch }, jobId) {
        commit('CHANGE_LOADING_STATE', { item: 'renewToken', loading: true });

        try {
            await this.$axios.patch(`/jobs/${jobId}/renew-token?include=timeline`).then(result=>{
                const timeline = result.data.timeline;
                commit("CHANGE_TIMELINE", timeline);
            })
        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('an-error-occurred-while-sending-reminder');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'renewToken', loading: false });
        }
    },
    async saveChecklist({ state, commit, dispatch }, checklist) {
        commit('CHANGE_LOADING_STATE', { item: 'jobChecklist', loading: true });

        const formData = object2formdata(checklist);
        formData.append('_method', 'PUT');

        try {
            const response = await this.$axios.post(`/checklists/${checklist.id}?include=checkpoints,checkpoints.files_info`, formData);
            commit('CHANGE_CHECKLIST', { checklist: response.data, type: 'CONTRACTOR' });
            dispatch('notifications/addNotification', { type: 'success', message: this.$i18n.t('saved'), duration: 1500 }, { root: true });
        } catch (error) {
            console.error(error);
            const errorMsg = error?.response?.data?.message || this.$i18n.t('an-error-occurred-while-saving');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'jobChecklist', loading: false });
        }
    },
    async sendMessage({ state, commit, dispatch }, { jobId, message }) {
        commit('CHANGE_LOADING_STATE', { item: 'message', loading: true });

        try {
            const response = await this.$axios.post(`/jobs/${jobId}/messages?include=timeline`, message);
            commit('CHANGE_TIMELINE', response.data.timeline);
            commit('SHOW_MESSAGE_INPUT_STATE', false);
        } catch (error) {
            const errorMsg = error?.response?.data?.message || this.$i18n.t('an-error-occurred-while-sending-message');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'message', loading: false });
        }
    },
    async getJobs({ state, commit, dispatch, rootGetters }, options = {}) {
        commit('CHANGE_LOADING_STATE', { item: 'list', loading: true });
        const { userContractor } = rootGetters;
        const { page, itemsPerPage } = state.listMeta;
        const params = new URLSearchParams({
            display: itemsPerPage,
            page,
            include: 'order.store,service,time_requests',
        });

        if (state.listMeta.searchQuery) {
            params.append('search', state.listMeta.searchQuery);
        }

        if (state.listMeta.statusFilter) {
            params.append('status', state.listMeta.statusFilter);
        }

        if (state.listMeta.serviceFilter) {
            params.append('service', state.listMeta.serviceFilter);
        }

        try {
            const response = await this.$axios.get(`/contractors/${userContractor.id}/jobs?${params}`);

            if (options.addMore && page !== 1) {
                commit('ADD_MORE_JOB_LIST', response.data.data);
            } else {
                commit('ADD_JOB_LIST', response.data.data);
            }

            commit('CHANGE_LIST_TOTAL', response.data.meta.total);
            commit('CHANGE_LIST_META', {
                page: response.data.meta.current_page,
                itemsPerPage: response.data.meta.per_page,
            });
        } catch (error) {
            const errorMsg = error?.response?.data?.error || this.$i18n.t('error-occurred-while-getting-data');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg }, { root: true });
        } finally {
            commit('CHANGE_LOADING_STATE', { item: 'list', loading: false });
        }
    },

    changePagination({ commit, dispatch }, listOptions) {
        commit('CHANGE_LIST_META', listOptions);
        dispatch('getJobs');
    },
};
