export default (ctx) => {
    const { store, app } = ctx;
    app.router.afterEach((to) => {
        const user = store.getters.user;

        if (to.name === app.localeRoute({ name: 'contractor-terms' })?.name) {
            return;
        }

        if (user && store.getters.userAffiliation === 'contractor') {
            if ('has_accepted_terms' in user && !user.has_accepted_terms) {
                app.router.replace(app.localePath({ name: 'contractor-terms' }));
            }
        }
    });
};
