// notification: {
//     type: 'success'|| 'info' || 'warning' || 'error',
//     message: String,
//     ?duration: Number,
// }

export const state = () => ({
    notifications: [],
});

export const mutations = {
    ADD_NOTIFICATION(state, notification) {
        state.notifications.push(notification);
    },
    DELETE_NOTIFICATION(state, index) {
        state.notifications.splice(index, 1);
    },
};

export const getters = {
    notifications: state => state.notifications.map(n => n.notification),
};

export const actions = {
    addNotification({ commit, dispatch }, notification) {
        const notificationObj = { notification };

        if (notification.duration) {
            const timeOut = setTimeout(function() {
                dispatch('deleteNotification', notification);
            }, notification.duration);
            notificationObj.timeout = timeOut;
        }

        commit('ADD_NOTIFICATION', notificationObj);
    },
    deleteNotification({ state, commit, dispatch }, notification) {
        const notificationIndex = state.notifications.map(n => n.notification).indexOf(notification);

        if (notificationIndex === -1) {
            return;
        }

        if (state.notifications[notificationIndex].timeout) {
            clearTimeout(state.notifications[notificationIndex].timeout);
        }
        commit('DELETE_NOTIFICATION', notificationIndex);
    },
};
