export const state = () => ({
    pageTitle: '',
    chosenAffiliationIndex: 0,
});

export const mutations = {
    SET_PAGE_TITLE(state, title) {
        state.pageTitle = title;
    },
    SET_AFFILIATION(state, affiliationIndex) {
        state.chosenAffiliationIndex = affiliationIndex;
    },
};

export const getters = {
    ownerGroupId: (state, getter) => getter.currentAffiliation?.owner_group_id || null,
    userGroup: (state, getter) => getter.currentAffiliation?.group || null,
    userStore: (state, getter) => getter.currentAffiliation?.store || null,
    userContractor: (state, getter) => getter.currentAffiliation?.contractor || null,
    currentAffiliation: (state) => {
        const affiliations = state.auth?.user?.affiliations;

        if (!affiliations) {
            return null;
        }

        return affiliations[state.chosenAffiliationIndex] || null;
    },
    pageTitle: state => state.pageTitle,
    userRole(store, getters) {
        const affiliation = getters.currentAffiliation;

        return affiliation?.role;
    },
    userAffiliation(store, getters) {
        const affiliationObj = getters.currentAffiliation;

        if (!affiliationObj) {
            return null;
        }

        const affiliationKeys = ['group', 'contractor', 'store'];
        const affiliation = affiliationKeys.find(key => affiliationObj[key] !== null);

        return affiliation;
    },
    user(state) {
        return state.auth.user;
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    async changeLocale({ getters, dispatch }, locale) {
        const userId = getters.user?.id;
        const localeMap = {
            en: 'en',
            'nb-no': 'nb',
        };

        try {
            await this.$axios.patch(`/users/${userId}/locale`, { locale: localeMap[locale] });
        } catch (error) {
            const errorMsg = error?.response?.data?.message || this.$i18n.t('could-not-save-preferred-locale');
            dispatch('notifications/addNotification', { type: 'error', message: errorMsg, duration: 2500 }, { root: true });
        }
    },
};
