export default ({ $axios, $auth, app }) => {
    $axios.onError(async (error) => {
        /**
         * Logout user if any axios request returns 401
         */

        if (error.response.status === 401 && window.location.pathname !== app.localePath('login')) {
            await $auth.logout();
            // Do aditionlal reload to clear vuex state
            window.location.reload();
        }

        return Promise.reject(error);
    });
};
